import React from "react"

const CallAction = () => (
  <div className="contact-section__box">
    <div className="contact-section__box-text">
      <h5 className="contact-section__box-title">
        Or Call Us @ <strong>858.699.2850</strong>
      </h5>
      <p>Reach Out Today and See Why We're Different</p>
    </div>
  </div>
)
export default CallAction
