import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useForm } from "react-hook-form"

const Form = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formsAction {
            mainForm
          }
        }
      }
    }
  `)
  const [submitted, setSubmitted] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm() // initialise the hook
  const FORM_GATEWAY_URL = data.site.siteMetadata.formsAction.mainForm
  const onSubmit = async data => {
    try {
      const formData = new URLSearchParams()
      for (const name in data) {
        formData.append(name, data[name])
      }

      console.log(JSON.stringify(data))
      await fetch(FORM_GATEWAY_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          console.log("Success:", data)
        })
      setSubmitted(true)
      reset()
    } catch (error) {
      setError(
        "submit",
        "submitError",
        `Oops! There seems to be an issue! ${error.message}`
      )
    }
  }
  const showSubmitError = msg => <p className="msg-error">{msg}</p>
  // phonemask
  const phoneMaskChange = e => {
    var x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    e.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
  }
  const showThankYou = (
    <div className="msg-confirm">
      <p>Awesome! Your message was sent.</p>
      <button class="btn_message" type="button" onClick={() => setSubmitted(false)}>
        Send another message?
      </button>
    </div>
  )
  const showForm = (
    <div>
      <h3>Request a Marketing Audit</h3>
      <form onSubmit={handleSubmit(onSubmit)} className="wpcf7" method="post">
        <div className="form-field">
          <input
            name="yourName"
            placeholder="Name"
            ref={register({ required: true })}
          />
          {errors.yourName && (
            <span className="wpcf7-not-valid-tip">This field is required</span>
          )}
        </div>
        <div className="form-field">
          <input
            name="yourPhone"
            id="PhoneNumber"
            placeholder="Phone"
            onChange={phoneMaskChange}
            ref={register({ required: true })}
          />
          {errors.yourPhone && (
            <span className="wpcf7-not-valid-tip">This field is required</span>
          )}
        </div>
        <div className="form-field">
          <input
            name="yourEmail"
            placeholder="Email"
            ref={register({ required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.yourEmail && (
            <span className="wpcf7-not-valid-tip">This field is required</span>
          )}
        </div>
        <div className="form-field">
          <div className="select-holder">
            <select name="yourGoal" ref={register({ required: true })}>
              <option value="#">Practice Area(s)</option>
              <option value="Plastic Surgery">Plastic Surgery</option>
              <option value="Med Spa, Dermatology">Med Spa, Dermatology</option>
              <option value="Dental">Dental</option>
              <option value="Eye Care">Eye Care</option>
              <option value="Podiatry">Podiatry</option>
              <option value="Urgent Care">Urgent Care</option>
              <option value="OBGYN">OBGYN</option>
              <option value="multiple practice areas">
                Multiple Practice Areas
              </option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="form-field">
          <textarea
            name="yourMessage"
            placeholder="Message"
            ref={register}
          ></textarea>
        </div>
        <div className="form-action">
          <input
            type="submit"
            value="SEND MESSAGE >>"
            disabled={isSubmitting}
            className="wpcf7-form-control wpcf7-submit btn btn_primary"
          />
        </div>
      </form>
      <div className="form-summary">
        <span>100% Confidential</span>
      </div>
    </div>
  )
  return (
    <div className="contact-form" id="contact-form">
      {errors && errors.submit && showSubmitError(errors.submit.message)}
      {submitted ? showThankYou : showForm}
    </div>
  )
}

export default Form
