import React from "react"

const SeenIcons = () => (
  <div className="trust-section">
    <h4 className="title_promo-partners">As seen in:</h4>
    <ul className="promo-partners promo-partners_seen">
      <li>
        <i className="icon icon_adweek" />
      </li>
      <li>
        <i className="icon icon_inc" />
      </li>
      <li>
        <i className="icon icon_losangeles" />
      </li>
      <li>
        <i className="icon icon_marketwatch" />
      </li>
      <li>
        <i className="icon icon_webmd" />
      </li>
    </ul>
  </div>
)

export default SeenIcons