import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import Slider from "react-slick"
// images
import DanielImg from "../../assets/images/clients/daniel.png"
import AssassaImg from "../../assets/images/clients/assassa.jpg"

class SimpleSlider extends React.Component {
  render() {
    var settings = {
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 8000,
      mobileFirst: true,
      className: "slider_testimonials",
    }
    return (
      <Slider {...settings}>
        <div className="testimonial">
          <blockquote>
            <div>
              <q>
                We've expanded from 3 locations to 15 locations and you are one
                of the main reasons why. We're getting an average of 630 phone
                calls and 220 email leads per month from our search engine
                marketing on Google; it's the highest it's ever been by far!
              </q>
              <div className="testimonial-author__img">
                <img src={DanielImg} alt="Daniel Taheri" />
              </div>
              <cite>Daniel Taheri, MD</cite>
              <span className="testimonial-author__role">
                CEO, LA Laser &amp; Skin Center
              </span>
            </div>
          </blockquote>
        </div>

        <div className="testimonial">
          <blockquote>
            <div>
              <q>
                Steve and his team are fantastic! They’ve done an unbelievable
                job with our lead generation and SEM so far. We are getting
                approximately 4 out of 5 new patients from our ​Google
                campaigns! That is no small feat. I am extremely satisfied with
                my results.
              </q>
              <div className="testimonial-author__img">
                <img src={AssassaImg} alt="Dr. Sam Assassa" />
              </div>
              <cite>Dr. Sam Assassa M.D.</cite>
              <span className="testimonial-author__role">
                Owner, Beverly Hills Aesthetics
              </span>
            </div>
          </blockquote>
        </div>
      </Slider>
    )
  }
}

const Testimonials = () => (
  <div className="testimonials-section" id="case-studies">
    <div className="container">
      <SimpleSlider />
      <div className="btn-holder">
        <a
          className="btn btn_primary"
          onClick={() => scrollTo("#contact-form")}
        >
          CONTACT US
        </a>
      </div>
    </div>
  </div>
)
export default Testimonials
