import React from "react"

const Services = () => (
  <div className="services-section" id="services">
    <div className="container">
      <div className="services-section__head">
        <h3>
          <mark>
            We are laser focused on making your phone ring and driving new
            patient revenue.
          </mark>
        </h3>
      </div>
      <div className="services__wrap">
        <section className="service-box">
          <div className="service-box__icon-holder">
            {/* <i class="icon icon_ppc"></i> */}
            <div className="tatsu-process-icon">
              {/*?xml version="1.0" encoding="utf-8"?*/}
              {/* Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  */}
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="64px"
                height="64px"
                viewBox="0 0 64 64"
                enableBackground="new 0 0 64 64"
                xmlSpace="preserve"
              >
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  d="M5,41L11,1L53,1L59,41"
                  style={{
                    strokeDasharray: "123, 125",
                    strokeDashoffset: 0,
                  }}
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  width={54}
                  height={22}
                  d="M5 41 L59 41 L59 63 L5 63 Z"
                  style={{
                    strokeDasharray: "152, 154",
                    strokeDashoffset: 0,
                  }}
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  d="M45,52A3,3 0,1,1 51,52A3,3 0,1,1 45,52"
                  style={{ strokeDasharray: "19, 21", strokeDashoffset: 0 }}
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeLinejoin="bevel"
                  strokeMiterlimit={10}
                  d="M23.998,20L
	31.998,12L39.998,20"
                  style={{ strokeDasharray: "23, 25", strokeDashoffset: 0 }}
                />
                <g>
                  <path
                    fill="none"
                    stroke="#000000"
                    strokeWidth={2}
                    strokeMiterlimit={10}
                    d="M31.998,12L31.998,32"
                    style={{
                      strokeDasharray: "20, 22",
                      strokeDashoffset: 0,
                    }}
                  />
                </g>
              </svg>
            </div>
          </div>
          <header className="service-box__head">
            <h3>Paid, Organic, Content, Email, Social?</h3>
          </header>
          <article className="service-box__content">
            <p>
              Each digital marketing channel is it's own discipline with a
              unique skill set. We are hyper-focused on driving ROI from
              campaigns that produce the lowest CPL and highest ROI. Are your
              marketing dollars really being maximized by the right people?
            </p>
          </article>
        </section>
        <section className="service-box">
          <div className="service-box__icon-holder">
            <div className="tatsu-process-icon">
              {/*?xml version="1.0" encoding="utf-8"?*/}
              {/* Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  */}
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="64px"
                height="64px"
                viewBox="0 0 64 64"
                enableBackground="new 0 0 64 64"
                xmlSpace="preserve"
              >
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  d="M63,18L63,54L1,54L1,10L22,10L30,18Z"
                  style={{
                    strokeDasharray: "208, 210",
                    strokeDashoffset: 0,
                  }}
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  d="M34.887,42H39c1.887,0,3-1,3-3c0-1-1-3-3-3
	c0-3.604-3.277-6-7-6c-3.295,0-6.413,2.978-7,6l0,0c-2,0-3,2-3,3c0,2,1,3,3,3h10.434"
                  style={{ strokeDasharray: "54, 56", strokeDashoffset: 0 }}
                />
              </svg>
            </div>
          </div>
          <header className="service-box__head">
            <h3>Web traffic means nothing if it doesn’t convert</h3>
          </header>
          <article className="service-box__content">
            <p>
              Many agencies focus only on “vanity metrics” such as traffic
              volume or Google rankings. After managing over $50 Million in web
              marketing budgets, we know that getting relevant traffic is only
              half the battle. We are laser focused on converting your web
              traffic into leads and new patients.
            </p>
          </article>
        </section>
        <section className="service-box">
          <div className="service-box__icon-holder">
            <div className="tatsu-process-icon">
              {/*?xml version="1.0" encoding="utf-8"?*/}
              {/* Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  */}
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="64px"
                height="64px"
                viewBox="0 0 64 64"
                enableBackground="new 0 0 64 64"
                xmlSpace="preserve"
              >
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  d="M21,40v5h22l0.001-5.107C49,36.195,53,29.564,53,22
	c0-11.598-9.402-21-21-21s-21,9.402-21,21C11,29.565,14.998,36.304,21,40z"
                  style={{
                    strokeDasharray: "142, 144",
                    strokeDashoffset: 0,
                  }}
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  d="M28,45L25,25"
                  style={{ strokeDasharray: "21, 23", strokeDashoffset: 0 }}
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  d="M36,45L39,25"
                  style={{ strokeDasharray: "21, 23", strokeDashoffset: 0 }}
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  d="M25,26L29,29L32,26L35,29L39,26"
                  style={{ strokeDasharray: "19, 21", strokeDashoffset: 0 }}
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  width={22}
                  height={6}
                  d="M21 45 L43 45 L43 51 L21 51 Z"
                  style={{ strokeDasharray: "56, 58", strokeDashoffset: 0 }}
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  width={18}
                  height={6}
                  d="M23 51 L41 51 L41 57 L23 57 Z"
                  style={{ strokeDasharray: "48, 50", strokeDashoffset: 0 }}
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  width={14}
                  height={6}
                  d="M25 57 L39 57 L39 63 L25 63 Z"
                  style={{ strokeDasharray: "40, 42", strokeDashoffset: 0 }}
                />
              </svg>
            </div>
          </div>
          <header className="service-box__head">
            <h3>Performance based &amp; 100% transparent</h3>
          </header>
          <article className="service-box__content">
            <p>
              We don't make money unless you make money - the way online
              marketing &amp; lead generation should be. You'll have 24/7 access
              to all raw data, costs, and results from all campaigns, including
              every phone call, email, and/or live chat lead you receive each
              day.
            </p>
          </article>
        </section>
      </div>
    </div>
  </div>
)

export default Services
